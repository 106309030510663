import * as yup from 'yup';
import GenericField from '@/shared/fields/generic-field';

export default class SelectableQueryFilterField extends GenericField {
    constructor(
        name,
        label,
        options,
        { required = false, autocompleteRequired = false, enabled = true } = {},
        filterParams = {
            textField: 'text',
            valueField: 'value',
            sortByKey: false
        },
    ) {
        super(name, label, filterParams);

        this.options = options || [];
        this.required = required;
        this.autocompleteRequired = autocompleteRequired;
        this.enabledValidation = enabled;
        this.type = 'selectablequeryfilter';
        this.textField = filterParams.textField;
        this.valueField = filterParams.valueField;
        this.sortByKey = filterParams.sortByKey;
    }

    _id(option) {
        if (!option) {
            return option;
        }

        if (typeof option === 'string' || option instanceof String) {
            return option;
        }

        return option.value;
    }

    _label(option) {
        if (!option) {
            return option;
        }

        if (typeof option === 'string' || option instanceof String) {
            return option;
        }

        return option.text;
    }

    forPresenter(value) {
        const option = this.options.find(
            (option) => option.value === this._id(value),
        );

        if (option) {
            return this._label(option);
        }

        return value;
    }

    forFilterInitialValue(value) {
        return this._id(value);
    }

    forFilterRules() {
        return undefined;
    }

    forFilterCast() {
        let yupChain = yup
            .string()
            .nullable(true)
            .label(this.text);

        return yupChain;
    }
}
